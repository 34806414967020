import React, { useEffect, useState, useContext, lazy, Suspense } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { Spin, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import PrivateRoute from "./PrivateRoute";
import IsLoggedIn from "./IsLogedIn";
import getCurrentLanguage from "../utils/CurrentLanguage";
const TVChartContainer = lazy(() => import("../pages/dashboard/index"));
const Home = lazy(() => import("../pages/home/Home"));
const Stocks = lazy(() => import("../pages/screener/Screener"));
const LoginForm = lazy(() => import("../components/SignIn"));
const Landing = lazy(() => import("../pages/landing/page"));
const FeedBacks = lazy(() => import("../pages/feedback/index"));
import { fetchFilterSettings } from "../features/FilterSettings/filterSettings";
const Privacy = lazy(() => import("../pages/privacy/Privacy"));
const Settings = lazy(() => import("../pages/settings/Settings"));
const AcceptablePolicy = lazy(() =>
  import("../pages/acceptablePolicy/AcceptablePolicy")
);
const RefundPolicy = lazy(() => import("../pages/refundPolicy/RefundPolicy"));
const Questions = lazy(() => import("./../pages/questions/Question"));
const MarketSnap = lazy(() => import("../pages/marketSnapShot/MarketSnap"));
const AboutUs = lazy(() => import("../pages/aboutus/index"));
const UploadData = lazy(() => import('../pages/uploadData/index'))
const Thanks = lazy(() => import('../pages/thanks/ThankYou'))
const SessionExpired = lazy(() =>
  import("../pages/SessionExpired/SessionExpired")
);
const ResetPassword = lazy(() =>
  import("../pages/ResetPassword/ResetPassword")
);

const SignIn = lazy(() => import("../pages/sign-in/page"));
const SignUp = lazy(() => import("../pages/sign-up/page"));
const ForgetPassword = lazy(() => import("../pages/forget-password/page"));
const NoMatch = lazy(() => import("../pages/no-match/page"));
// const Subscription = lazy(() => import("../pages/subscription/page"));
const Subscription = lazy(() => import("../pages/Subscription-old/Subscription"));

import {
  getMyUserData,
  getSubscriptionDetails,
  updateSubscription,
} from "../features/Auth/authSlice";
const TreeMap = lazy(() => import("../pages/heatMap/Treemap"));
import { KuzzleContext } from "../App";
import AbouUs from './../pages/aboutus/index';
import SaudiFundingDayYear from './../pages/saudi-founding-day-year-package-offer/index';

const RouteConfig = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isAuth = useSelector((state) => state.auth.isAuth);
  const user = useSelector((state) => state.auth.user);

  const [loading, setLoading] = useState(false);
  const [room, setRoom] = useState(null);
  const { kuzzleHttp: kuzzle, kuzzleSocket: kuzzleSocket } =
    useContext(KuzzleContext);
  const currentLanguage = getCurrentLanguage();

  useEffect(() => {
    const dispatchAsyncThunkOnLoad = async () => {
      await dispatch(fetchFilterSettings(kuzzle)).unwrap();
      if (isAuth) {
        await dispatch(getMyUserData()).unwrap();
        await dispatch(getSubscriptionDetails()).unwrap();
      }
      setLoading(true);
    };

    dispatchAsyncThunkOnLoad();
  }, [dispatch]);

  useEffect(() => {
    const subscribeRoom = async () => {
      if (isAuth) {
        const roomId = await kuzzleSocket.realtime.subscribe(
          "bianat",
          "users",
          {
            equals: {
              username: user?.username,
            },
          },
          async (notification) => {
            if (notification.type === "document") {
              if (notification.action === "update") {
                const { subscription, subscriptionIds } =
                  notification.result._source;

                await dispatch(getMyUserData()).unwrap();

                if (subscription === "paid") {
                  navigate("/dashboard");
                } else if (subscription === "free") {
                  navigate("/settings");
                }
              }
            }
          }
        );
        setRoom(roomId);
      }
    };

    subscribeRoom();

    return () => {
      if (room) {
        kuzzleSocket.realtime.unsubscribe(room);
        setRoom(null);
      }
    };
  }, [isAuth]);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = `${t("BIANAT")}`;
  }, [t]);

  if (!loading) {
    return (
      <Spin
        size="large"
        style={{
          justifyContent: "center",
          marginTop: "25%",
          display: "flex",
        }}
      />
    );
  }

  return (
      <Suspense
          fallback={
              <Spin
                  size="large"
                  style={{
                      justifyContent: 'center',
                      marginTop: '25%',
                      display: 'flex',
                  }}
              />
          }
      >
          <Routes>
              <Route
                  path="/forget-password"
                  element={<IsLoggedIn Component={ForgetPassword} />}
              />
              <Route
                  path="/sign-up"
                  element={<IsLoggedIn Component={SignUp} />}
              />
              <Route
                  path="/sign-in"
                  element={<IsLoggedIn Component={SignIn} />}
              />
              <Route path="/" element={<IsLoggedIn Component={Landing} />} />
              <Route path="/aboutus" element={<AboutUs />} />
              <Route
                  path="/saudi-founding-day-year-package-offer"
                  element={<SaudiFundingDayYear/>}
              />
              {/* <Route path="/login" element={<LoginForm />} /> */}
              <Route
                  path="/dashboard"
                  element={<PrivateRoute Component={Home} />}
              />

              {/* <Route path="/subscription" element={<PrivateRoute Component={Subscription} />} /> */}

              <Route
                  path="/console"
                  element={<PrivateRoute Component={TVChartContainer} />}
              />

              <Route
                  path="/screener"
                  element={<PrivateRoute Component={Stocks} />}
              />
              <Route path="/subscription" element={<Subscription />} />
              <Route
                  path="/settings"
                  element={<PrivateRoute Component={Settings} />}
              />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/acceptable" element={<AcceptablePolicy />} />
              <Route path="/refund" element={<RefundPolicy />} />
              <Route path="/questions" element={<Questions />} />
              <Route
                  path="/marketPulse"
                  element={<PrivateRoute Component={MarketSnap} />}
              />

              {/* <Route path="/dashboard" element={<TVChartContainer />} /> */}
              <Route path="/session/expired" element={<SessionExpired />} />
              <Route path="/upload-data" element={<UploadData />} />
              <Route path="/thanks" element={<Thanks />} />
              <Route path="/feedbacks" element={
                <FeedBacks />
              } />
              <Route
                  path="/reset-password/:token"
                  element={<ResetPassword />}
              />
              <Route
                  path="/treemap"
                  element={<PrivateRoute Component={TreeMap} />}
              />
              <Route path="*" element={<NoMatch />} />
          </Routes>
      </Suspense>
  )
};

export default React.memo(RouteConfig);
